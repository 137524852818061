import React, { useEffect } from "react"
import PropTypes from "prop-types"
import { useStaticQuery, graphql } from "gatsby"
import Header from "@components/header/header"
import Embeds from "@components/embeds"
import Footer from "@components/footer/footer"
import "bootstrap/dist/css/bootstrap.min.css"
import "font-awesome/css/font-awesome.min.css"
import "cookieconsent/build/cookieconsent.min.css"
import "@sass/main.scss"

const Layout = ({ children }) => {
  const loadScriptAsync = uri => {
    return new Promise((resolve, reject) => {
      const s = document.createElement("script")
      s.src = uri
      s.id = `cookie-consent`
      s.async = true
      s.onload = () => {
        resolve()
      }
      const script = document.getElementsByTagName("script")[0]
      script.parentNode.insertBefore(s, script)
    })
  }
  useEffect(() => {
    var scriptLoaded = loadScriptAsync(
      "//cdn.jsdelivr.net/npm/cookieconsent@3/build/cookieconsent.min.js"
    )

    scriptLoaded.then(function() {
      window.cookieconsent.initialise({
        container: document.getElementById("cookieChoiceInfo"),
        palette: {
          popup: {
            background: "#024c71",
            text: "#ffffff",
          },
          button: {
            background: "#1b9485",
          },
        },
        position: "bottom-left",
        theme: "classic",
        content: {
          message: "Cookies help us deliver our services. By using our services, you agree to our use of cookies.",
          href: "/cookie-policy",
          dismiss: "Got it!",
          target: "_self",
        },
      })
    })
  })

  const data = useStaticQuery(graphql`
    query SiteTitleQuery {
      site {
        siteMetadata {
          title
        }
      }
    }
  `)

  return (
    <>
      <Embeds />
      <Header siteTitle={data.site.siteMetadata.title} />
      <main>{children}</main>
      <Footer />
      <div id="cookieChoiceInfo" className="text-center" />
    </>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
