import React from "react"
import { Link } from "gatsby"
import { Nav, Accordion, Card } from "react-bootstrap"

import { StaticImage } from "gatsby-plugin-image"
import menulinks from "@data/menulinks.json"

const Menu = ({ toggle }) => (
  <div className="navigation">
    <div className="close-button">
      <div className="text-right">
        <button className="close-btn" onClick={toggle}>
          <StaticImage
            src="../../images/close-btn.svg"
            width={24}
            layout="constrained"
            alt="x"
          />
        </button>
      </div>
      <Nav className="flex-column">
        <Accordion>
          {menulinks.menuLinks.map((link, i) =>
            link.submenu ? (
              <Card>
                <Accordion.Toggle as={Card.Header} eventKey={i}>
                  {link.external ? (
                    <a
                      href={link.url}
                      target="_blank"
                      rel="noopener noreferrer"
                      className="d-inline-block"
                    >
                      {link.name}
                    </a>
                  ) : (
                    <Link to={link.url} className="d-inline-block">
                      {link.name}
                    </Link>
                  )}
                </Accordion.Toggle>
                <Accordion.Collapse eventKey={i}>
                  <Card.Body>
                    {link.submenu.map(submenuItem => (
                      <Nav.Item>
                        {submenuItem.external &&
                        submenuItem.sameWindow !== true ? (
                          <a
                            href={submenuItem.url}
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            {submenuItem.name}
                          </a>
                        ) : submenuItem.external && submenuItem.sameWindow ? (
                          <a href={submenuItem.url}>{submenuItem.name}</a>
                        ) : (
                          <Link to={submenuItem.url}>{submenuItem.name}</Link>
                        )}
                      </Nav.Item>
                    ))}
                  </Card.Body>
                </Accordion.Collapse>
              </Card>
            ) : (
              <Nav.Item>
                {link.external && link.sameWindow !== true ? (
                  <a href={link.url} target="_blank" rel="noopener noreferrer">
                    {link.name}
                  </a>
                ) : link.external && link.sameWindow ? (
                  <a href={link.url}>{link.name}</a>
                ) : (
                  <Link to={link.url}>{link.name}</Link>
                )}
              </Nav.Item>
            )
          )}
        </Accordion>
      </Nav>
      <div className="py-3 d-block d-md-none">
        <a
          className="btn btn-primary my-2"
          role="button"
          href="tel:719-359-5995"
        >
          <i className="fa fa-phone pl-0 pr-2"></i>719-359-5995
        </a>
      </div>
    </div>
  </div>
)
export default Menu
