import React from "react"
import { Link } from "gatsby"
import SideBarMenu from "@components/sidebar/sidebar"
import Sticky from "react-sticky-el"
import { StaticImage } from "gatsby-plugin-image"
// import Logo from "@images/logo.png"

import menulinks from "@data/menulinks.json"

const Header = ({ type }) => (
  <header>
    <div className="header-top d-none d-md-block">
      <div className="container">
        <div className="d-md-flex justify-content-md-between align-items-md-center align-items-lg-center">
          <div>
            <a
              className="mr-3"
              href="https://www.facebook.com/itspecialistsinc/"
              target="_blank"
              rel="noopener noreferrer"
              aria-label="facebook"
            >
              <i className="fa fa-facebook"></i>
            </a>
            <a
              className="mr-3"
              href="https://www.linkedin.com/company/itspecialistsinc"
              target="_blank"
              rel="noopener noreferrer"
              aria-label="linkedin"
            >
              <i className="fa fa-linkedin"></i>
            </a>
            <a
              className="mr-3"
              aria-label="youtube"
              href="https://www.youtube.com/channel/UCAeDeiMpj8QfZ9CR_yVwPmg"
              target="_blank"
              rel="noopener noreferrer"
            >
              <i className="fa fa-youtube-play"></i>
            </a>
            <a href="mailto:info@itspecialists.net">info@itspecialists.net</a>
          </div>
        </div>
      </div>
    </div>
    <Sticky
      className="sticky-wrapper"
      stickyClassName="is-sticky"
      stickyStyle={{ transform: "unset", zIndex: "99" }}
    >
      <div className="header">
        <div className="container">
          <div className="d-flex justify-content-between align-items-center header-content-wrapper">
            <Link to="/">
              <StaticImage
                className="header-logo"
                src="../../images/logo.png"
                alt="IT SPECIALISTS"
                placeholder="none"
              />
            </Link>

            {/* full header navigation if screen is at least 1280px */}
            <div className="full-header-nav">
              {menulinks.menuLinks.map(link =>
                // if submenu exists
                link.submenu ? (
                  <div className="has-submenu">
                    {link.external && link.sameWindow !== true ? (
                      <a
                        href={link.url}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        {link.name}
                      </a>
                    ) : link.external && link.sameWindow ? (
                      <a href={link.url}>{link.name}</a>
                    ) : (
                      <Link to={link.url}>{link.name}</Link>
                    )}
                    <div className="submenu-wrapper">
                      {link.submenu.map(submenuItem => (
                        <>
                          {submenuItem.external &&
                          submenuItem.sameWindow !== true ? (
                            <a
                              href={submenuItem.url}
                              target="_blank"
                              rel="noopener noreferrer"
                            >
                              {submenuItem.name}
                            </a>
                          ) : submenuItem.external && submenuItem.sameWindow ? (
                            <a href={submenuItem.url}>{submenuItem.name}</a>
                          ) : (
                            <Link to={submenuItem.url}>{submenuItem.name}</Link>
                          )}
                        </>
                      ))}
                    </div>
                  </div>
                ) : (
                  // if submenu does not exist
                  <div>
                    {link.external && link.sameWindow !== true ? (
                      <a
                        href={link.url}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        {link.name}
                      </a>
                    ) : link.external && link.sameWindow ? (
                      <a href={link.url}>{link.name}</a>
                    ) : (
                      <Link to={link.url}>{link.name}</Link>
                    )}
                  </div>
                )
              )}
            </div>

            {/* hide full header nav and show this sidebar menu if screen is less than 1280px */}
            <div className="condensed-header-nav">
              <a
                className="btn btn-transparent btn-sm d-none d-md-inline-block mr-3"
                role="button"
                href="tel:719-359-5995"
              >
                <i className="fa fa-phone pl-0 pr-2"></i>719-359-5995
              </a>
              <div className="d-inline-block">
                <SideBarMenu />
              </div>
            </div>
          </div>
        </div>
      </div>
    </Sticky>
  </header>
)

export default Header
