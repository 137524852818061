import React from "react"
import Helmet from "react-helmet"

export default () => {
  return (
    <Helmet>
      /* Google Site Verification */
      <meta name='google-site-verification' content='rbIzek0YeoWrUO5HsrH_gLviTSVoekOpB5ivjhKuRTM' />
      /* /Google Site Verification */

      /* Pinterest Site Verification */
      <meta name='p:domain_verify' content='8de5b25de41040b7d205aec7f4fdcab1'/>
      /* /Pinterest Site Verification */

      /* HubSpot Embed */
      <script type='text/javascript' id='hs-script-loader' async defer src='//js.hs-scripts.com/3843464.js'></script>
      /* /HubSpot Embed */
    </Helmet>
  )
}
