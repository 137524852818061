import React from "react"
import { Link } from "gatsby"
import { StaticImage } from "gatsby-plugin-image"
// import Logo from "@images/logo.png"
// import RingCentral from "@images/ringcentral-partner-logo.png"

const footer = props => {
  return (
    <>
      <div className="footer">
        <div className="container">
          <div className="row">
            <div className="col-md-4 col-lg-3 mb-4">
              <StaticImage
                src="../../images/logo.png"
                placeholder="blurred"
                // width={250}
                style={{ filter: "brightness(0) invert(1)" }}
                alt="IT SPECIALISTS"
              />
              <p className="text-white-50 small py-4">
                <strong>
                  Providing the highest quality managed services available in
                  the market
                </strong>
              </p>
              <StaticImage
                src="../../images/ringcentral-partner-logo.png"
                placeholder="blurred"
                // width={200}
                alt="RingCentral | Partner"
              />
              <a style={{'display':'block', 'width':'100%', 'max-width':'170px', 'margin': '2em auto 0 auto'}} href="https://upcity.com/profiles/it-specialists/colorado-springs"><img style={{'width':'100%'}} src="https://upcity-marketplace.s3.amazonaws.com/badge/192/basic_full_color/7971b8882ba9e6768100244f6a695185.png" alt="TOP IT PROVIDER" /></a>
              <a style={{'display':'block', 'width':'100%', 'margin-top': '1.5em'}} href="https://upcity.com/profiles/it-specialists"><img style={{'width':'100%'}} src="https://app.upcity.com/images/badges/featured.png" alt="Featured on UpCity" /></a>
            </div>
            <div className="col-md-3 col-lg-2 mb-4">
              <h5 className="text-white text-uppercase">Company</h5>
              <ul className="list-unstyled">
                <li>
                  <Link to="/about-us/">About Us</Link>
                </li>
                <li>
                  <a
                    // href="https://blog.itspecialists.net/"
                    href="/blog"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Blog
                  </a>
                </li>
                <li>
                  <Link to="/our-clients/">Our Clients</Link>
                </li>
                <li>
                  <Link to="/contact/">Contact Us</Link>
                </li>
                <li>
                  <Link to="/privacy-policy/">Privacy Policy</Link>
                </li>
                <li>
                  <Link to="/cookie-policy/">Cookie Policy</Link>
                </li>
                <li>
                  <Link to="/terms-of-use/">Terms of Use</Link>
                </li>
              </ul>
            </div>
            <div className="col-md-4 col-lg-3 mb-4">
              <h5 className="text-white text-uppercase">Solutions</h5>
              <ul className="list-unstyled">
                <li>
                  <Link to="/managed-services/">Managed Services</Link>
                </li>
                <li>
                  <Link to="/cloud-it-solutions/">Cloud IT Solutions</Link>
                </li>
                <li>
                  <Link to="/help-desk/">Help Desk</Link>
                </li>
                <li>
                  <Link to="/voip-and-connectivity/">
                    Hosted VoIP and Connectivity
                  </Link>
                </li>
                <li>
                  <Link to="/network-cabling/">Network Cabling</Link>
                </li>
                <li>
                  <Link to="/special-projects/">Special Projects</Link>
                </li>
                <li>
                  <Link to="/file-sync-and-share/">File Sync and Share</Link>
                </li>
                <li>
                  <Link to="/backup-and-disaster-recovery/">
                    Backup and Disaster Recovery
                  </Link>
                </li>
                <li>
                  <Link to="/web-solutions/">Web Solutions</Link>
                </li>
              </ul>
            </div>
            <div className="col-md-4 col-lg-3 mb-4">
              <h5 className="text-white text-uppercase">Contact</h5>
              <ul className="list-unstyled contact-list">
                <li className="address">
                  6625 Delmonico Drive
                  <br />
                  Colorado Springs, CO 80919
                </li>
                <li className="phone">
                  <a href="tel:719-359-5995">719-359-5995</a>
                </li>
                <li></li>
                <li className="fax">
                  <a href="tel:719-593-9903">719-593-9903</a>
                </li>
                <li className="email">
                  <a href="mailto:info@itspecialists.net">
                    info@itspecialists.net
                  </a>
                </li>
              </ul>
            </div>
            <div className="col-md-2 col-lg-1">
              <ul className="list-unstyled d-inline-flex d-lg-block social-list">
                <li>
                  <a
                    className="facebook"
                    href="https://www.facebook.com/itspecialistsinc/"
                    target="_blank"
                    rel="noopener noreferrer"
                    aria-label="facebook"
                  >
                    <i className="fa fa-facebook"></i>
                  </a>
                </li>
                <li>
                  <a
                    className="linkedin"
                    href="https://www.linkedin.com/company/itspecialistsinc"
                    target="_blank"
                    rel="noopener noreferrer"
                    aria-label="linkedin"
                  >
                    <i className="fa fa-linkedin"></i>
                  </a>
                </li>
                <li>
                  <a
                    className="youtube"
                    aria-label="youtube"
                    href="https://www.youtube.com/channel/UCAeDeiMpj8QfZ9CR_yVwPmg"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <i className="fa fa-youtube-play"></i>
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
      <div className="copyright">
        <div className="container">
          © 2021 IT Specialists. All rights reserved.
        </div>
      </div>
    </>
  )
}

export default footer
