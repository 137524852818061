import React from "react"
import SideBar from "react-sidebar"
// import { Motion, spring, presets } from "react-motion"
import Menu from "./menu"

// import NavIcon from "@images/nav-icon.png"
import { StaticImage } from "gatsby-plugin-image"

class SideBarMenu extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      sidebarOpen: false,
      sidebarpullRight: true,
      toggle: false,
    }

    this.onSetSidebarOpen = this.onSetSidebarOpen.bind(this)
  }
  onSetSidebarOpen(state) {
    this.setState({ sidebarOpen: state.open, toggle: !this.state.toggle })
  }
  toggleMenu() {
    this.setState({
      sidebarOpen: !this.state.sidebarOpen,
      toggle: !this.state.toggle,
    })
  }

  handleKeyDown = ev => {
    // check keys if you want
    if (ev.keyCode === 13) {
      this.focus()
    }
  }

  focus = () => this.ref.focus

  handleRef = component => {
    this.ref = component
  }

  render() {
    return (
      <SideBar
        sidebar={
          <Menu toggle={e => this.toggleMenu()} /> // Menu list
        }
        open={this.state.sidebarOpen}
        onSetOpen={this.onSetSidebarOpen}
        pullRight={this.state.sidebarpullRight}
        sidebarClassName="sidebar"
        styles={{
          sidebar: {
            width: "300px",
            position: "fixed",
            zIndex: "3",
          },
          root: { position: "relative", overflow: "visible" },
          overlay: { backgroundColor: "rgba(0, 0, 0, 0.65)", zIndex: 2 },
          content: {
            position: "relative",
            overflow: "visible",
            cursor: "pointer",
          },
        }}
      >
        <button
          className="pill"
          onClick={e => this.toggleMenu(e)}
          onKeyDown={e => this.handleKeyDown(e)}
        >
          <StaticImage
            alt="MENU"
            src="../../images/nav-icon.png"
            width={30}
            layout="constrained"
          />
          {/* <img src="../../images/nav-icon.png" width="35px" alt="MENU" /> */}
        </button>
      </SideBar>
    )
  }
}

export default SideBarMenu
